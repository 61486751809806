import {
  IChore,
  IGeneration,
  IIdea,
  IMeta,
  IPenance,
  IPlan,
  ISettings,
} from "./types";
import { getToken, onResponse } from "./token";

export class Api {
  private static get(url: string) {
    return fetch(process.env.REACT_APP_API_URL + url, {
      headers: {
        "X-Auth": getToken(),
      },
    }).then(onResponse);
  }

  private static post(url: string, body: object) {
    return fetch(process.env.REACT_APP_API_URL + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth": getToken(),
      },
      body: JSON.stringify(body),
    }).then(onResponse);
  }

  private static delete(url: string) {
    return fetch(process.env.REACT_APP_API_URL + url, {
      method: "DELETE",
      headers: {
        "X-Auth": getToken(),
      },
    }).then(onResponse);
  }

  static planController = {
    async getAllPlans(): Promise<IPlan[]> {
      const url = `/plans`;
      return await Api.get(url);
    },

    async getPlanById(id: string): Promise<IPlan> {
      const url = `/plan?id=${id}`;
      return await Api.get(url);
    },

    async addPlan(plan: Omit<IPlan, "_id">) {
      return await Api.post("/add-plan", plan);
    },

    async updatePlan(plan: IPlan) {
      const { _id, ...rest } = plan;
      return await Api.post("/update-plan", {
        planId: _id,
        ...rest,
      });
    },

    async deletePlan(planId: string) {
      const url = `/delete-plan?planId=${planId}`;
      return await Api.get(url);
    },
  };

  static choreController = {
    async generate(day: string, mode: string) {
      const url = `/generate-from-plans?day=${day}&mode=${mode}`;
      return await Api.get(url);
    },

    async getChores(day: string): Promise<IChore[]> {
      const url = `/chores?day=${day}`;
      return await Api.get(url);
    },

    async getGeneration(day: string) {
      const url = `/generation?day=${day}`;
      const { generation } = await Api.get(url);
      return generation as IGeneration | null;
    },

    async deleteGeneration(day: string) {
      const url = `/delete-generation?day=${day}`;
      return await Api.get(url);
    },

    async getMeta(day: string) {
      const url = `/meta?day=${day}`;
      const { meta } = await Api.get(url);
      return meta as IMeta | null;
    },

    async getMonthMeta(month: string) {
      const url = `/meta?month=${month}`;
      const { meta } = await Api.get(url);
      return meta as IMeta[] | null;
    },

    async getPenance(day: string) {
      const url = `/penance?from=${day}`;
      return (await Api.get(url)) as IPenance;
    },

    async updateMeta(meta: IMeta) {
      const url = `/update-meta`;
      return await Api.post(url, meta);
    },

    async deleteMeta(day: string) {
      const url = `/delete-meta?day=${day}`;
      return await Api.get(url);
    },

    async toggleChoreStatus(
      choreId: string,
      entryId: string,
      sharedId?: string
    ) {
      const url =
        `/toggle-chore-status?chore_id=${choreId}&entry_id=${entryId}` +
        (sharedId ? `&shared_id=${sharedId}` : "");
      return await Api.get(url);
    },
  };

  static settingsController = {
    async getSettings() {
      const url = `/settings`;
      return await Api.get(url);
    },

    async updateSettings(settings: ISettings) {
      const { autoGenerate } = settings;
      const url = `/update-settings?autoGenerate=${autoGenerate}`;
      return await Api.get(url);
    },
  };

  static ideaController = {
    async getIdeas() {
      const url = `/idea`;
      return (await Api.get(url)) as IIdea[];
    },

    async getIdeaById(id: string) {
      const url = `/idea/${id}`;
      return (await Api.get(url)) as IIdea;
    },

    async addIdea(idea: Omit<IIdea, "_id">) {
      return await Api.post("/idea", idea);
    },

    async updateIdea(idea: IIdea) {
      const { _id, ...rest } = idea;
      return await Api.post(`/idea/${_id}`, rest);
    },

    async deleteIdea(id: string) {
      const url = `/idea/${id}`;
      return await Api.delete(url);
    },
  };
}
