import { IdeaBox } from "../../common/components/idea/IdeaBox";
import { LinkOta } from "../navigation";

export const IdeaPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <LinkOta to={"/"} link>
        Home
      </LinkOta>
      <IdeaBox />
      <div></div>
    </div>
  );
};
