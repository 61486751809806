import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Api } from "../../../ota/utils/api";
import { IIdea } from "../../../ota/utils/types";

const IdeaBoxContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const IdeasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DayGroup = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
`;

const DayTitle = styled.h2`
  margin: 0 0 12px;
  font-size: 1.2rem;
  color: #333;
`;

const IdeaCard = styled.div`
  background: #f5f5f5;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const IdeaContentStyled = styled.div`
  flex: 1;
  text-align: left;
  white-space: pre-wrap; /* so newlines display */
  font-size: 1.4em;
`;

const TagsStyled = styled.div`
  font-size: 0.8rem;
  color: gray;
  margin-top: 4px;
`;

const IdeaActions = styled.div`
  display: flex;
  gap: 12px;
`;

const InputRow = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const StyledTextArea = styled.textarea`
  flex: 1;
  padding: 8px;
  resize: vertical;
`;

const Button = styled.button`
  padding: 6px 9px;
  cursor: pointer;
`;

/**
 * Group ideas by YYYY-MM-DD date string.
 */
const groupIdeasByDay = (ideas: IIdea[]) => {
  return ideas.reduce<Record<string, IIdea[]>>((acc, idea) => {
    const dayString = idea.time.split("T")[0]; // Simplified date extraction
    if (!acc[dayString]) {
      acc[dayString] = [];
    }
    acc[dayString].push(idea);
    return acc;
  }, {});
};

export function IdeaBox() {
  const queryClient = useQueryClient();

  // Fetch all ideas
  const {
    data: ideas,
    isLoading,
    isError,
  } = useQuery(["ideas"], Api.ideaController.getIdeas);

  // Local state for filtering and adding new ideas
  const [filterDay, setFilterDay] = useState("all");
  const [newContent, setNewContent] = useState("");

  // Mutations
  const addIdeaMutation = useMutation({
    mutationFn: (newIdea: Omit<IIdea, "_id">) =>
      Api.ideaController.addIdea(newIdea),
    onSuccess: () => {
      queryClient.invalidateQueries(["ideas"]);
      setNewContent("");
    },
  });

  const updateIdeaMutation = useMutation({
    mutationFn: (updatedIdea: IIdea) =>
      Api.ideaController.updateIdea(updatedIdea),
    onSuccess: () => {
      queryClient.invalidateQueries(["ideas"]);
    },
  });

  const deleteIdeaMutation = useMutation({
    mutationFn: (id: string) => Api.ideaController.deleteIdea(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["ideas"]);
    },
  });

  // Group ideas by day
  const groupedIdeas = useMemo(() => {
    if (!ideas) return {};
    return groupIdeasByDay(ideas);
  }, [ideas]);

  // Handler for adding a new idea
  const handleAddIdea = () => {
    if (!newContent.trim()) return;
    addIdeaMutation.mutate({
      content: newContent,
      time: new Date().toISOString(),
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.metaKey) {
      e.preventDefault(); // prevent newline
      handleAddIdea();
    }
  };

  if (isLoading) return <IdeaBoxContainer>Loading ideas...</IdeaBoxContainer>;
  if (isError) return <IdeaBoxContainer>Error loading ideas.</IdeaBoxContainer>;

  // Filter logic
  const dayKeys = Object.keys(groupedIdeas).sort((a, b) => b.localeCompare(a));
  const filteredKeys =
    filterDay === "all" ? dayKeys : dayKeys.filter((d) => d === filterDay);

  return (
    <IdeaBoxContainer>
      <FilterContainer>
        <div>
          <label htmlFor="dayFilter">Filter by day: </label>
          <select
            id="dayFilter"
            value={filterDay}
            onChange={(e) => setFilterDay(e.target.value)}
          >
            <option value="all">All</option>
            {dayKeys.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
      </FilterContainer>

      <InputRow>
        <StyledTextArea
          rows={3}
          placeholder="New idea..."
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={handleAddIdea}>Add</Button>
      </InputRow>

      <IdeasWrapper>
        {filteredKeys.map((day) => (
          <DayGroup key={day}>
            <DayTitle>{day}</DayTitle>
            {groupedIdeas[day]?.map((idea) => (
              <IdeaItem
                key={idea._id}
                idea={idea}
                onUpdate={(updated) => updateIdeaMutation.mutate(updated)}
                onDelete={() => {
                  if (window.confirm("Delete this idea?")) {
                    deleteIdeaMutation.mutate(idea._id);
                  }
                }}
              />
            ))}
          </DayGroup>
        ))}
      </IdeasWrapper>
    </IdeaBoxContainer>
  );
}

/**
 * A subcomponent to render each idea item with edit/delete controls.
 */
interface IdeaItemProps {
  idea: IIdea;
  onUpdate: (idea: IIdea) => void;
  onDelete: () => void;
}

function IdeaItem({ idea, onUpdate, onDelete }: IdeaItemProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempContent, setTempContent] = useState(idea.content);

  const handleSave = () => {
    onUpdate({ ...idea, content: tempContent });
    setIsEditing(false);
  };

  return (
    <IdeaCard>
      {isEditing ? (
        <StyledTextArea
          rows={3}
          value={tempContent}
          onChange={(e) => setTempContent(e.target.value)}
        />
      ) : (
        <IdeaContent content={idea.content} />
      )}
      <IdeaActions>
        {isEditing ? (
          <>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          </>
        ) : (
          <>
            <Button onClick={() => setIsEditing(true)}>Edit</Button>
            <Button onClick={onDelete}>Delete</Button>
          </>
        )}
      </IdeaActions>
    </IdeaCard>
  );
}

const IdeaContent = ({ content }: { content: string }) => {
  const words = content.split(" ");
  const text = words.filter((word) => !word.startsWith("#")).join(" ");
  const tags = words.filter((word) => word.startsWith("#"));

  return (
    <div style={{ textAlign: "left" }}>
      <IdeaContentStyled>{text}</IdeaContentStyled>
      {tags.length > 0 && <TagsStyled>{tags.join(" ")}</TagsStyled>}
    </div>
  );
};
