export function message(
  text: string,
  animationTime: number,
  color: string,
  textColor = "white"
) {
  const appearingTime = animationTime / 6;

  // Create the container div
  const container = document.createElement("div");
  container.style.position = "fixed";
  container.style.top = "0";
  container.style.left = "0";
  container.style.right = "0";
  container.style.bottom = "0";
  container.style.padding = "10px";
  container.style.backgroundColor = color;
  container.style.display = "flex";
  container.style.justifyContent = "center";
  container.style.zIndex = "9999";
  container.style.opacity = "0";
  container.style.transition = `opacity ${appearingTime}ms`;

  if (window.screen.width <= 800) {
    container.style.paddingTop = "80px";
  } else {
    container.style.alignItems = "center";
  }

  // Create the text element
  const textElement = document.createElement("span");
  textElement.textContent = text;
  textElement.style.color = textColor;
  textElement.style.fontFamily = "Arial, sans-serif";
  textElement.style.fontSize = "2rem";
  textElement.style.textAlign = "center";

  // Append the text element to the container
  container.appendChild(textElement);

  // Append the container to the body
  document.body.appendChild(container);

  // Fade in the container
  setTimeout(() => {
    container.style.opacity = "1";
  }, 0);

  // Fade out the container and remove it after the animation time
  setTimeout(() => {
    container.style.opacity = "0";
    container.addEventListener("transitionend", () => {
      container.remove();
    });
  }, animationTime);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, appearingTime);
  });
}
