import styled, { css } from "styled-components";
import { LinkOta } from "../navigation";
import { FC } from "react";

const Styled = styled(LinkOta)<{ highlighted?: boolean }>`
  margin-left: 20px;
  font-size: 1.9em !important;
  text-decoration: none;
  ${(props) =>
    props.highlighted &&
    css`
      color: #78eb93;
      font-weight: bold;
    `}
`;

interface IdeaLinkProps {}

export const IdeaLink: FC<IdeaLinkProps> = (props) => {
  return (
    <Styled to={"/idea"} highlighted={false}>
      ⚚
    </Styled>
  );
};
