import { Clock, Star } from "grommet-icons";
import { DUE_COLOR } from "./task";
import React, { useEffect } from "react";
import { endOfToday, endOfTomorrow } from "../../common/util/date";
import { TaskPriority } from "../dtos";
import { useGlobal } from "../global";
import { isSpecialTag, SPECIAL_TAG_POOL, useTagIdsFromNames } from "./tags";

type ExtraMarker =
  | "idea"
  | "dueToday"
  | "dueTomorrow"
  | "important"
  | typeof SPECIAL_TAG_POOL;
export const getExtraMarker = (input: string): ExtraMarker | null => {
  if (input.startsWith("*")) {
    return "idea";
  }
  if (input.startsWith("@")) {
    return SPECIAL_TAG_POOL;
  }
  if (input.startsWith("..")) {
    return "dueToday";
  }
  if (input.startsWith(".")) {
    return "important";
  }
  if (input.startsWith(",")) {
    return "dueTomorrow";
  }
  return null;
};

export const clearMarkers = (input: string) => {
  const marker = getExtraMarker(input);
  switch (marker) {
    case "dueToday":
      return input.slice(2).trim();
    case "important":
      return input.slice(1).trim();
    case "dueTomorrow":
      return input.slice(1).trim();
    case SPECIAL_TAG_POOL:
      return input.slice(1).trim();
    case "idea":
      return input.slice(1).trim();
    default:
      return input;
  }
};

export const getMarkerIcon = (inputValue: string) => {
  const extra = getExtraMarker(inputValue);
  switch (extra) {
    case "dueToday":
      return <Clock color={DUE_COLOR.TODAY} />;
    case "dueTomorrow":
      return <Clock color={DUE_COLOR.TOMORROW} />;
    case "important":
      return <Star />;
    case SPECIAL_TAG_POOL:
      return "🌀";
    case "idea":
      return "💡";
    default:
      return null;
  }
};

export const getMarkerDueBy = (marker: ExtraMarker | null) => {
  switch (marker) {
    case "dueToday":
      return endOfToday().getTime();
    case "dueTomorrow":
      return endOfTomorrow().getTime();
    default:
      return undefined;
  }
};

export const getMarkerPriority = (marker: ExtraMarker | null): TaskPriority => {
  switch (marker) {
    case "dueToday":
    case "dueTomorrow":
      return "planned";
    case "important":
      return "important";
    case SPECIAL_TAG_POOL:
      return "to-be-planned";
    default:
      return "inbox";
  }
};

export const getTags = (
  quickTags: string[],
  currentTag: string,
  extraMarker: ExtraMarker | null
) => {
  const tags = [...quickTags];

  if (!isSpecialTag(currentTag)) {
    tags.push(currentTag);
  }

  if (extraMarker === SPECIAL_TAG_POOL) {
    tags.push(SPECIAL_TAG_POOL);
  }

  return tags;
};

export const useOnKeyWhenNoFocus = (key: string, action: () => void) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if 'f' key is pressed and no input is active
      if (
        event.key === key &&
        !(
          document.activeElement instanceof HTMLInputElement ||
          document.activeElement instanceof HTMLTextAreaElement
        )
      ) {
        action();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to run only on mount
};

export const useOnKey = (key: string, action: () => void) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === key) {
        action();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to run only on mount
};

export const useQuickTags = (quickFormMode: string, inputValue: string) => {
  const { activeTags } = useGlobal();
  const tags = activeTags.map((tag) => tag.name);

  const [usedTags, setUsedTags] = React.useState<string[]>([]);

  useEffect(() => {
    if (quickFormMode !== "add") {
      return;
    }

    const foundTags = tags.filter((tag) => {
      return new RegExp(`\\b${tag.toLowerCase()}\\b`).test(
        inputValue.toLowerCase()
      );
    });

    setUsedTags(foundTags);

    return () => {
      setUsedTags([]);
    };
  }, [quickFormMode, inputValue]);

  return useTagIdsFromNames(usedTags);
};
